import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./QuizComponent.scss";
import QuizFooterComponent from "./../quizFooterComponent/QuizFooterComponent";
import QuizAnswerComponent from "./../quizAnswerComponent/QuizAnswerComponent";
import { useContext, useState } from "react";
import Context from "./../../../context/Context";
import LeaderboardComponent from "./../../leaderboardComponent/LeaderboardComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import QuestionTypeComponent from "../quizTypeComponent/QuestionTypeComponent";
import QuizMobileFixedHeader from "../quizMobileFixedHeader/QuizMobileFixedHeader";
import FillButtonAnimateComponent from "../../../shared/fillButtonAnimateComponent/FillButtonAnimateComponent";
import QuestionStatsComponent from "../questionStatsComponent/QuestionStatsComponent";
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent";

const QuizComponent = () => {
  const navigate = useNavigate();
  const {
    questionData,
    answerData,
    noOfQuestions,
    quizPage,
    setQuizPage,
    selectedAnswer,
    setSelectedAnswer,
    leaderBoardOpen,
    leaderboardData,
    socket,
    host
  } = useContext(Context);

  const [timerText, setTimerText] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to leave the game?")) {
        setfinishStatus(true);
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return (e.returnValue =
      "Data will be lost if you leave the page, are you sure?");
  };

  const handleNextQuestion = () => {
    if (questionData?.questionNumber === noOfQuestions) {
      socket &&
        socket.send(
          JSON.stringify({
            action: "goToPodium",
          })
        );
    } else if (questionData?.questionNumber !== noOfQuestions) {
      socket &&
        socket.send(
          JSON.stringify({
            action: "sendNextQuestion",
          })
        );
    }
  };

  const handleShowQuestionResults = () => {
    setQuizPage("hostModeStats")
  };

  useEffect(() => {
    document.getElementById("quizComp_content").scrollTo(0, 0)
  }, [quizPage])

  const goToHome = () => {
    if (!questionData) {
      navigate("/");
    }
  }

  useEffect(() => {
    goToHome()
  }, [questionData]);

  const [finishStatus, setfinishStatus] = useState(false);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    window.addEventListener("beforeunload", onReloadButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      window.removeEventListener("beforeunload", onReloadButtonEvent);
    };
  }, []);

  return (
    <div className="quizComponent">

      <div
        className="quizComponent__Content" id="quizComp_content"
        style={{ width: !isMobile ? leaderBoardOpen ? 'calc(100vw - 380px)' : "100vw" : "100vw" }}
      >
        {isMobile &&
          <QuizMobileFixedHeader
            questionCount={questionData?.questionNumber}
            noOfQuestions={noOfQuestions}
          />
        }

        {(quizPage==="answer") ? (
          <QuizAnswerComponent
            noOfQuestions={noOfQuestions}
            questionCount={questionData?.questionNumber}
            answer={answerData}
            selectedAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            hostModeFlag={questionData?.hostModeFlag}
          />
        ) : (quizPage==="hostModeStats") ? (
          <QuestionStatsComponent
            barChartData={answerData?.hostModeData}
            handleNextQuestion={handleNextQuestion}
          />
        ) : (
          <QuestionTypeComponent
            question={questionData}
            setSelectedAnswer={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
            questionCount={questionData?.questionNumber}
            noOfQuestions={noOfQuestions}
          />
        )}

        {((quizPage==="answer") && host && (
          <div className="quizComponent__nextBtnFooter">
            (answerData?.hostModeFlag && <FillButtonComponent 
              displayText="Show Question Results"
              handleClick={handleShowQuestionResults} 
            />)
            <FillButtonAnimateComponent 
              displayText={(questionData?.questionNumber === noOfQuestions ? "End Quiz" : "Next Question")}
              handleClick={handleNextQuestion}
              duration={(leaderboardData?.noOfActivePlayers > 1) ? 5 : 0} 
            />
          </div>
        ))}

      </div>

      {isMobile || <QuizFooterComponent timerText={timerText} />}
      {isMobile || <LeaderboardComponent quiz />}
    </div>
  );
};
export default QuizComponent;